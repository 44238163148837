import { reducerMatch } from 'utils/common';
import REDUCER_TYPES from 'constants/reducerTypes';
import REQUEST_FIELDS from 'constants/requestFields';
import * as actionTypes from 'store/redux/cart/actionTypes';

const initialState = {
  cart: [],
  addToCartRequestData: { ...REQUEST_FIELDS },
  checkoutRequestData: { ...REQUEST_FIELDS },
  checkout: {},
  changeAmountCartRequestData: { ...REQUEST_FIELDS },
  changeAmountCartId: null,
  deleteProductCartRequestData: { ...REQUEST_FIELDS },
  deleteProductCartId: null,
  submitPurchaseRequestData: { ...REQUEST_FIELDS },
  cancelPurchaseRequestData: { ...REQUEST_FIELDS },
  purchaseStatus: null,
  purchaseStatusRequestData: { ...REQUEST_FIELDS },
  sendMessageRequestData: { ...REQUEST_FIELDS },
};

const reducers = [
  [REDUCER_TYPES.SETTER, actionTypes.SET_CART, 'cart'],
  [REDUCER_TYPES.REQUEST_DATA, actionTypes.ADD_TO_CART, 'addToCartRequestData'],
  [
    REDUCER_TYPES.REQUEST_DATA,
    actionTypes.FETCH_CHECKOUT,
    'checkoutRequestData',
  ],
  [REDUCER_TYPES.SETTER, actionTypes.SET_CHECKOUT, 'checkout'],
  [
    REDUCER_TYPES.REQUEST_DATA,
    actionTypes.CHANGE_AMOUNT_CART,
    'changeAmountCartRequestData',
  ],
  [
    REDUCER_TYPES.SETTER,
    actionTypes.SET_CHANGE_AMOUNT_CART_ID,
    'changeAmountCartId',
  ],
  [
    REDUCER_TYPES.REQUEST_DATA,
    actionTypes.DELETE_PRODUCT_CART,
    'deleteProductCartRequestData',
  ],
  [
    REDUCER_TYPES.SETTER,
    actionTypes.SET_DELETE_PRODUCT_CART_ID,
    'deleteProductCartId',
  ],
  [
    REDUCER_TYPES.REQUEST_DATA,
    actionTypes.SUBMIT_PURCHASE,
    'submitPurchaseRequestData',
  ],
  [
    REDUCER_TYPES.REQUEST_DATA,
    actionTypes.CANCEL_PURCHASE,
    'cancelPurchaseRequestData',
  ],
  [
    REDUCER_TYPES.REQUEST_DATA,
    actionTypes.FETCH_PURCHASE_STATUS,
    'purchaseStatusRequestData',
  ],
  [REDUCER_TYPES.SETTER, actionTypes.SET_PURCHASE_STATUS, 'purchaseStatus'],
  [
    REDUCER_TYPES.REQUEST_DATA,
    actionTypes.SEND_MESSAGE,
    'sendMessageRequestData',
  ],
];

const reducer = (state = initialState, { type, payload }) => {
  const match = reducerMatch(type, payload, reducers, state);
  if (match) return match;

  switch (type) {
    default:
      return state;
  }
};

export default reducer;
