import { useState, useEffect } from 'react';
import PageLayout from 'components/common/PageLayout';
import Header from 'components/Header';
import Footer from 'components/Footer';
import { routes } from 'routing/routes';
import { Link, Redirect, useHistory, useLocation } from 'react-router-dom';
import { classnameDefaults } from 'utils/common';
import { useTitle } from 'react-use';

const initialFormState = {
  purchaseCode: '',
};

const PurchaseBlock = ({ title, label, status }) => {
  const icon = status === 'next' ? 'far fa-hourglass' : 'far fa-check-circle';
  return (
    <div className={`purchase-status-item ${status}`}>
      <i className={icon}></i>
      <div>
        <h3>{title}</h3>
        <p>{label}</p>
      </div>
    </div>
  );
};

const PurchaseStatus = ({
  purchaseStatus,
  purchaseStatusRequestData,
  fetchPurchaseStatus,
  resetPurchaseSearch,
}) => {
  useTitle(`Estado de compra | ${process.env.REACT_APP_PAGE_TITLE}`);
  const [formData, setFormData] = useState(initialFormState);

  const fieldHandler = (field, e) => {
    const value = e.target.value;
    setFormData({
      ...formData,
      [field]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    fetchPurchaseStatus(formData);
  };

  const renderStatus = (purchase) => {
    const blocks = [
      {
        id: 1,
        title: 'Compra realizada',
        label: `Fecha de compra: ${purchase.date}.`,
      },
      {
        id: 2,
        title: 'Pago pendiente',
        label:
          'Una vez verificado el pago se procede a la preparación del envío.',
      },
      {
        id: 3,
        title: 'Pago verificado',
        label: 'El pago fue verificado, pronto prepararemos tu pedido.',
      },
      {
        id: 4,
        title: 'En preparación',
        label: 'Estamos preparando tu pedido para ser enviado.',
      },
      {
        id: 5,
        title: 'Pedido enviado',
        label: 'Ya enviamos tu pedido.',
      },
      {
        id: 6,
        title: 'Pedido entregado',
        label: 'Ya recibiste tu pedido.',
      },
    ];

    const status = parseInt(purchase.status);

    if (status === 10) {
      return (
        <div className='cancelled-purchase'>
          <p>
            <i class='fas fa-exclamation-circle'></i> Su compra ha sido
            cancelada. Si cree que esto es un error, por favor{' '}
            <Link to={routes.contacto.path}>escríbanos un mensaje</Link> y
            revisaremos su situación.
          </p>
        </div>
      );
    } else {
      return blocks.map((item) => (
        <PurchaseBlock
          key={item.id}
          title={item.title}
          label={item.label}
          status={
            item.id < status
              ? 'previous'
              : item.id > status
              ? 'next'
              : 'current'
          }
        />
      ));
    }
  };

  const clearSearchHandler = (e) => {
    e.preventDefault();
    resetPurchaseSearch();
  };

  return (
    <>
      <PageLayout>
        <Header />
        <main className='main-login'>
          <div className='wrapper'>
            <h2 className='page-title'>Ver estado de compra</h2>

            {purchaseStatus ? (
              <div className='purchase-status'>
                {renderStatus(purchaseStatus)}

                <a href='#' onClick={clearSearchHandler}>
                  Buscar otra compra
                </a>
              </div>
            ) : (
              <>
                <p>Ingresa el código de tu compra.</p>

                <form onSubmit={handleSubmit}>
                  <label className='label'>Código</label>
                  <input
                    type='text'
                    name='purchaseCode'
                    required
                    className='input'
                    value={formData['purchaseCode']}
                    onChange={(e) => fieldHandler('purchaseCode', e)}
                  />

                  <button
                    disabled={purchaseStatusRequestData.loading}
                    className={`button ${classnameDefaults([
                      purchaseStatusRequestData.loading,
                      'disabled',
                    ])}`}
                  >
                    {!purchaseStatusRequestData.loading ? (
                      'Buscar compra'
                    ) : (
                      <>
                        <span>Buscando</span>{' '}
                        <i className='fas fa-spinner fa-pulse'></i>
                      </>
                    )}
                  </button>
                </form>

                <p className='register-label'>
                  <Link to={routes.base.path}>Volver al inicio</Link>
                </p>
              </>
            )}
          </div>
        </main>
      </PageLayout>
      <Footer />
    </>
  );
};

export default PurchaseStatus;
