import Contacto from './Contacto';
import { connect } from 'react-redux';
import { sendMessage } from 'store/redux/cart/actionCreators';
import { getSendMessageRequestData } from 'store/redux/cart/selectors';

const mapStateToProps = (state) => ({
  sendMessageRequestData: getSendMessageRequestData(state),
});

const mapDispatchToProps = {
  sendMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(Contacto);
