import * as actionTypes from "store/redux/cart/actionTypes";
import api from "store/api";
import ENDPOINTS from "constants/endpoints";
import * as authSelectors from "store/redux/auth/selectors";
import {
  processActionCreatorSetters,
  processActionCreatorStatusFunctions,
  objectToFormData,
  fireCartSuccess,
  fireError,
  firePurchaseSuccess,
  fireSuccess,
  setStore,
} from "utils/common";
import { routes } from "routing/routes";

const settersArray = [
  ["setCart", actionTypes.SET_CART],
  ["setCheckout", actionTypes.SET_CHECKOUT],
  ["setChangeAmountCartId", actionTypes.SET_CHANGE_AMOUNT_CART_ID],
  ["setDeleteProductCardId", actionTypes.SET_DELETE_PRODUCT_CART_ID],
  ["setPurchaseStatus", actionTypes.SET_PURCHASE_STATUS],
];

const actionCreatorsStatusArray = [
  ["addToCart", actionTypes.ADD_TO_CART],
  ["fetchCheckout", actionTypes.FETCH_CHECKOUT],
  ["changeAmountCart", actionTypes.CHANGE_AMOUNT_CART],
  ["deleteProductCart", actionTypes.DELETE_PRODUCT_CART],
  ["submitPurchase", actionTypes.SUBMIT_PURCHASE],
  ["cancelPurchase", actionTypes.CANCEL_PURCHASE],
  ["fetchPurchaseStatus", actionTypes.FETCH_PURCHASE_STATUS],
  ["sendMessage", actionTypes.SEND_MESSAGE],
];

export const setters = processActionCreatorSetters(settersArray);
export const statuses = processActionCreatorStatusFunctions(
  actionCreatorsStatusArray
);

export const resetPurchaseSearch = () =>
  setStore(actionTypes.SET_PURCHASE_STATUS, null);

export const addToCart =
  (product, amount, history) => async (dispatch, getState) => {
    dispatch(statuses.addToCartLoading());

    const token = authSelectors.getAuthToken(getState());

    try {
      const response = await api({
        method: "post",
        url: ENDPOINTS.addToCart(),
        data: objectToFormData({
          id: product.id,
          amount,
          token,
        }),
      });

      if (!response.data.status) {
        fireError(response.data.message);
        dispatch(statuses.addToCartError());
        return;
      }

      const cartResponse = await api({
        method: "get",
        url: ENDPOINTS.getCart(),
        params: { token },
      });

      await dispatch(setters.setCart(cartResponse.data));
      fireCartSuccess("¡Producto añadido!", null, history);

      dispatch(statuses.addToCartSuccess());
    } catch (error) {
      dispatch(statuses.addToCartError());
    }
  };

export const fetchCheckout = (hideLoading) => async (dispatch, getState) => {
  if (!hideLoading) dispatch(statuses.fetchCheckoutLoading());

  const token = authSelectors.getAuthToken(getState());

  try {
    const response = await api({
      method: "get",
      url: ENDPOINTS.validateCart(),
      params: { token },
    });

    if (!response.data.status) {
      dispatch(statuses.fetchCheckoutError());
      return;
    }

    await dispatch(setters.setCart(response.data.data.cart));
    await dispatch(setters.setCheckout(response.data.data));
    dispatch(statuses.fetchCheckoutSuccess());
  } catch (error) {
    dispatch(statuses.fetchCheckoutError());
  }
};

export const changeAmountCart =
  (id, operation) => async (dispatch, getState) => {
    dispatch(statuses.changeAmountCartLoading());
    dispatch(setters.setChangeAmountCartId(id));

    const token = authSelectors.getAuthToken(getState());

    try {
      const response = await api({
        method: "post",
        url: ENDPOINTS.changeAmountCart(),
        data: objectToFormData({ token, id, operation }),
      });

      if (!response.data.status) {
        fireError(response.data.message);
        dispatch(statuses.changeAmountCartError());
        return;
      }

      await dispatch(fetchCheckout(true));
      dispatch(setters.setChangeAmountCartId(null));
      dispatch(statuses.changeAmountCartSuccess());
    } catch (error) {
      dispatch(statuses.changeAmountCartError());
    }
  };

export const deleteProductCart = (id) => async (dispatch, getState) => {
  dispatch(statuses.deleteProductCartLoading());
  dispatch(setters.setDeleteProductCardId(id));

  const token = authSelectors.getAuthToken(getState());

  try {
    const response = await api({
      method: "post",
      url: ENDPOINTS.deleteProductCart(),
      data: objectToFormData({ token, id }),
    });

    if (!response.data.status) {
      fireError(response.data.message);
      dispatch(statuses.deleteProductCartError());
      return;
    }

    await dispatch(fetchCheckout(true));
    dispatch(setters.setDeleteProductCardId(null));
    dispatch(statuses.deleteProductCartSuccess());
  } catch (error) {
    dispatch(statuses.deleteProductCartError());
  }
};

export const submitPurchase =
  (formData, checkoutType, history) => async (dispatch, getState) => {
    dispatch(statuses.submitPurchaseLoading());

    const token = authSelectors.getAuthToken(getState());

    Object.keys(formData).forEach(
      (key) => (formData[key] = formData[key].trim())
    );

    try {
      const endpoint =
        checkoutType === "mercadopago"
          ? ENDPOINTS.purchaseMercadopago
          : ENDPOINTS.purchaseTransfer;

      const response = await api({
        method: "post",
        url: endpoint(),
        data: objectToFormData({ token, ...formData }),
      });

      if (!response.data.status) {
        fireError(response.data.message);
        dispatch(statuses.submitPurchaseError());
        return;
      }

      const cartResponse = await api({
        method: "get",
        url: ENDPOINTS.getCart(),
        params: { token },
      });

      await dispatch(setters.setCart(cartResponse.data));

      if (checkoutType === "mercadopago") {
        await firePurchaseSuccess(
          "Abriendo MercadoPago",
          "Espere mientras es redireccionado a MercadoPago",
          response.data.payment_link
        );
      } else {
        history.push({
          pathname: routes.purchaseDone.path,
          state: {
            type: "transfer",
            code: response.data.codigo,
          },
        });
      }

      dispatch(statuses.submitPurchaseSuccess());
    } catch (error) {
      dispatch(statuses.submitPurchaseError());
    }
  };

export const cancelPurchase = (history) => async (dispatch, getState) => {
  dispatch(statuses.cancelPurchaseLoading());

  const token = authSelectors.getAuthToken(getState());

  try {
    const response = await api({
      method: "post",
      url: ENDPOINTS.cancelPurchase(),
      data: objectToFormData({ token }),
    });

    if (!response.data.status) {
      fireError(response.data.message);
      dispatch(statuses.cancelPurchaseError());
      return;
    }

    await fireSuccess(
      "Compra cancelada",
      "Serás redireccionado a la tienda..."
    );

    history.push(routes.tienda.base.path);

    dispatch(statuses.cancelPurchaseSuccess());
  } catch (error) {
    dispatch(statuses.cancelPurchaseError());
  }
};

export const fetchPurchaseStatus = (formData) => async (dispatch, getState) => {
  dispatch(statuses.fetchPurchaseStatusLoading());

  const token = authSelectors.getAuthToken(getState());

  try {
    const response = await api({
      method: "post",
      url: ENDPOINTS.fetchPurchaseStatus(),
      data: objectToFormData({ token, ...formData }),
    });

    if (!response.data.status) {
      fireError(response.data.message);
      dispatch(statuses.fetchPurchaseStatusError());
      return;
    }

    dispatch(setters.setPurchaseStatus(response.data.data));
    dispatch(statuses.fetchPurchaseStatusSuccess());
  } catch (error) {
    dispatch(statuses.fetchPurchaseStatusError());
  }
};

export const sendMessage = (formData) => async (dispatch, getState) => {
  dispatch(statuses.sendMessageLoading());

  try {
    const response = await api({
      method: "post",
      url: ENDPOINTS.sendMail(),
      data: objectToFormData({ ...formData }),
    });

    if (!response.data.status) {
      fireError(response.data.message);
      dispatch(statuses.sendMessageError());
      return;
    }

    await fireSuccess(
      "¡Mensaje enviado!",
      "Nos pondremos en contacto con usted a la brevedad."
    );
    dispatch(statuses.sendMessageSuccess());
  } catch (error) {
    dispatch(statuses.sendMessageError());
  }
};
