import CheckoutStepper from './CheckoutStepper';
import { connect } from 'react-redux';
import {
  getCartItemsValue,
  getChangeAmountCartRequestData,
  getChangeAmountCartId,
  getDeleteProductCartRequestData,
  getDeleteProductCartId,
  getSubmitPurchaseRequestData,
} from 'store/redux/cart/selectors';
import {
  changeAmountCart,
  deleteProductCart,
  submitPurchase,
} from 'store/redux/cart/actionCreators';
import { getUser } from 'store/redux/auth/selectors';

const mapStateToProps = (state) => ({
  cartTotalValue: getCartItemsValue(state),
  changeAmountCartRequestData: getChangeAmountCartRequestData(state),
  changeAmountCartId: getChangeAmountCartId(state),
  deleteProductCartRequestData: getDeleteProductCartRequestData(state),
  deleteProductCartId: getDeleteProductCartId(state),
  submitPurchaseRequestData: getSubmitPurchaseRequestData(state),
  user: getUser(state),
});

const mapDispatchToProps = {
  changeAmountCart,
  deleteProductCart,
  submitPurchase,
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutStepper);
