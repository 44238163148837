import IniciarSesion from './IniciarSesion';
import { connect } from 'react-redux';
import { login } from 'store/redux/auth/actionCreators';
import { getLoginRequestData } from 'store/redux/auth/selectors';

const mapStateToProps = (state) => ({
  loginRequestData: getLoginRequestData(state),
});

const mapDispatchToProps = {
  login,
};

export default connect(mapStateToProps, mapDispatchToProps)(IniciarSesion);
