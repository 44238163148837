export const routes = {
  base: {
    path: "/",
    title: "Inicio",
  },
  purchaseStatus: {
    path: "/estado-compra",
    title: "Estado de compra",
  },
  newPassword: {
    path: "/nueva-contrasena",
    title: "Cambiar contraseña",
  },
  recoverPassword: {
    path: "/recuperar-contrasena",
    title: "Recuperar contraseña",
  },
  registro: {
    path: "/registro",
    title: "Registro",
  },
  login: {
    path: "/iniciar-sesion",
    title: "Iniciar Sesión",
  },
  nosotros: {
    path: "/nosotros",
    title: "Nosotros",
  },
  checkout: {
    path: "/checkout",
    title: "Checkout",
  },
  purchaseDone: {
    path: "/compra-finalizada",
    title: "Compra Finalizada",
  },
  tienda: {
    base: {
      path: "/tienda",
      title: "Tienda",
    },
    category: {
      path: "/tienda/:category",
      title: "Tienda",
    },
    subcategory: {
      path: "/tienda/:category/:subcategory",
      title: "Tienda",
    },
    product: {
      path: "/tienda/:category/:subcategory/:product",
      title: "Tienda",
    },
  },
  contacto: {
    path: "/contacto",
    title: "Contacto",
  },
  search: {
    path: "/buscar",
    title: "Search",
  },
  ofertas: {
    path: "/tienda/alacena/ofertas",
    title: "Ofertas",
  },
};
