import { useRef } from "react";
import { Link } from "react-router-dom";
import PageLayout from "components/common/PageLayout";
import Header from "components/Header";
import Footer from "components/Footer";
import { routes } from "routing/routes";
import { classnameDefaults } from "utils/common";
import { useTitle } from "react-use";

const IniciarSesion = ({ login, loginRequestData }) => {
  useTitle(`Iniciar Sesión | ${process.env.REACT_APP_PAGE_TITLE}`);

  const usernameRef = useRef();
  const passwordRef = useRef();

  const loginHandler = (e) => {
    e.preventDefault();

    const username = usernameRef.current.value.trim();
    const password = passwordRef.current.value.trim();

    login(username, password);
  };

  return (
    <>
      <PageLayout>
        <Header />
        <main className="main-login">
          <h2 className="page-title session-tittle">Iniciar sesión</h2>
          <div className="wrapper session-init">
            <form onSubmit={loginHandler}>
              <input
                type="text"
                name="username"
                required
                className="input"
                ref={usernameRef}
                placeholder="Usuario o email"
              />

              <input
                type="password"
                name="password"
                required
                className="input"
                ref={passwordRef}
                placeholder="Contraseña"
              />

              <div className="session-button">
                <button
                  disabled={loginRequestData.loading}
                  className={`button ${classnameDefaults([
                    loginRequestData.loading,
                  ])}`}
                >
                  Iniciar sesión
                </button>
              </div>

              <div className="session-button">
                <Link to={routes.recoverPassword.path} className="forgot-pass">
                  Me olvidé la contraseña
                </Link>
              </div>

              <p className="register-label">
                Si todavía no te registraste, hace{" "}
                <Link to={routes.registro.path}>click acá</Link> para hacerlo.
              </p>
            </form>
          </div>
        </main>
      </PageLayout>
      <Footer />
    </>
  );
};

export default IniciarSesion;
