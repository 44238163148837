import Register from './Register';
import { connect } from 'react-redux';
import { register, clearRegister } from 'store/redux/auth/actionCreators';
import { getRegisterRequestData } from 'store/redux/auth/selectors';

const mapStateToProps = (state) => ({
  registerRequestData: getRegisterRequestData(state),
});

const mapDispatchToProps = {
  register,
  clearRegister,
};

export default connect(mapStateToProps, mapDispatchToProps)(Register);
