import { useState } from "react";
import LogoMercadopago from "assets/images/logo-mercadopago.png";
import CheckoutRow from "components/Checkout/CheckoutStepper/CheckoutRow";
import { classnameDefaults, formatPrice } from "utils/common";
import { useHistory } from "react-router-dom";
import ScrollToTop from "components/ScrollToTop";

const initialFormState = {
  nombre: "",
  apellido: "",
  dni: "",
  ciudad: "",
  provincia: "",
  address: "",
  telefono: "",
  correo: "",
  vendedor: "",
  notas: "",
};

const CheckoutStepper = ({
  list,
  cartTotalValue,
  changeAmountCartRequestData,
  changeAmountCart,
  changeAmountCartId,
  deleteProductCartRequestData,
  deleteProductCartId,
  deleteProductCart,
  submitPurchaseRequestData,
  submitPurchase,
  user,
}) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [selectedCheckoutType, setSelectedCheckoutType] = useState(null);
  const [formData, setFormData] = useState(initialFormState);

  const history = useHistory();

  const navigateHandler = (type) => {
    setCurrentStep(1);
    setSelectedCheckoutType(type);
  };

  const fieldHandler = (field, e) => {
    const value = e.target.value;
    setFormData({
      ...formData,
      [field]: value,
    });
  };

  const submitHandler = (e) => {
    e.preventDefault();
    submitPurchase(formData, selectedCheckoutType, history);
  };

  const totalPriceParts = formatPrice(cartTotalValue);

  return (
    <div
      className={`checkout-slider ${classnameDefaults([
        currentStep === 1,
        "finish",
      ])}`}
    >
      <div className="checkout-slider-track">
        <ScrollToTop />
        <div className="checkout-slider-left">
          <div className="shopping-cart-table">
            <div className="row row-head">
              <div className="col">Título</div>
              <div className="col">Cantidad</div>
              <div className="col">Precio por unidad</div>
              <div className="col">Precio total</div>
              <div className="col"></div>
            </div>
            {list.map((item) => (
              <CheckoutRow
                user={user}
                key={item.id}
                item={item}
                changeAmountCartRequestData={changeAmountCartRequestData}
                changeAmountCart={changeAmountCart}
                changeAmountCartId={changeAmountCartId}
                deleteProductCartRequestData={deleteProductCartRequestData}
                deleteProductCartId={deleteProductCartId}
                deleteProductCart={deleteProductCart}
              />
            ))}
            <div className="row row-last">
              <div className="col center">Total</div>
              <div className="col"></div>
              <div className="col"></div>
              <div className="col">
                <b className="total">
                  <span>{totalPriceParts[0]}</span>
                  <span className="product-decimal">{totalPriceParts[1]}</span>
                </b>
              </div>
              <div className="col"></div>
            </div>
          </div>

          <div className="payment-options">
            <a onClick={() => navigateHandler("mercadopago")}>
              <div>
                <span>Pagar con</span>
                <img src={LogoMercadopago} />
              </div>
            </a>
            <a onClick={() => navigateHandler("transferencia")}>
              <div>
                <span className="no-margin">
                  Pagar con
                  <br />
                  <b>depósito/transferencia</b>
                </span>
              </div>
            </a>
          </div>
        </div>
        <div className="checkout-slider-right">
          <a className="go-back" onClick={() => setCurrentStep(0)}>
            <i className="fas fa-arrow-left"></i> Volver atrás
          </a>
          <h3>
            Completar el pago de{" "}
            <b>
              <span>{totalPriceParts[0]}</span>
              <span className="product-decimal">{totalPriceParts[1]}</span>
            </b>
          </h3>

          <div className="checkout-info">
            {selectedCheckoutType === "mercadopago" && (
              <p>
                <i className="fas fa-check-circle"></i>
                Será redireccionado a MercadoPago para abonar su compra con el
                medio de pago que elijas.
              </p>
            )}

            <p>
              <i className="fas fa-check-circle"></i>
              Una vez confirmado el pago nos contactaremos con vos para
              coordinar el envío.
            </p>
            {user && (
              <p>
                <i className="fas fa-check-circle"></i>
                El monto mínimo de compra mayorista es de $20.000.
              </p>
            )}
          </div>

          <form className="user-data-form" onSubmit={submitHandler}>
            {!user && (
              <>
                <label className="label">Nombre</label>
                <input
                  className="input"
                  type="text"
                  name="nombre"
                  required
                  value={formData["nombre"]}
                  onChange={(e) => fieldHandler("nombre", e)}
                />

                <label className="label">Apellido</label>
                <input
                  className="input"
                  type="text"
                  name="apellido"
                  required
                  value={formData["apellido"]}
                  onChange={(e) => fieldHandler("apellido", e)}
                />

                <label className="label">
                  DNI <span>(sólo numeros)</span>
                </label>
                <input
                  className="input"
                  type="number"
                  name="dni"
                  required
                  value={formData["dni"]}
                  onChange={(e) => fieldHandler("dni", e)}
                />

                <label className="label">Dirección</label>
                <input
                  className="input"
                  type="text"
                  name="address"
                  placeholder="Dirección completa"
                  required
                  value={formData["address"]}
                  onChange={(e) => fieldHandler("address", e)}
                />

                <label className="label">Ciudad</label>
                <input
                  className="input"
                  type="text"
                  name="ciudad"
                  required
                  value={formData["ciudad"]}
                  onChange={(e) => fieldHandler("ciudad", e)}
                />

                <label className="label">Provincia</label>
                <input
                  className="input"
                  type="text"
                  name="provincia"
                  required
                  value={formData["provincia"]}
                  onChange={(e) => fieldHandler("provincia", e)}
                />

                <label className="label">Teléfono</label>
                <input
                  className="input"
                  type="tel"
                  name="telefono"
                  required
                  value={formData["telefono"]}
                  onChange={(e) => fieldHandler("telefono", e)}
                />

                <label className="label">Email</label>
                <input
                  className="input"
                  type="email"
                  name="correo"
                  required
                  value={formData["correo"]}
                  onChange={(e) => fieldHandler("correo", e)}
                />
              </>
            )}

            {/*  <label className="label">
              Código vendedor/a <span>(opcional)</span>
            </label>
            <input
              className="input"
              type="text"
              name="vendedor"
              required
              value={formData["vendedor"]}
              onChange={(e) => fieldHandler("vendedor", e)}
            /> */}

            {/*  <label className="label">
              Notas o aclaraciones <span>(opcional)</span>
            </label>
            <textarea
              className="input"
              name="notas"
              value={formData["notas"]}
              onChange={(e) => fieldHandler("notas", e)}
            ></textarea> */}

            <button
              disabled={submitPurchaseRequestData.loading}
              className={`button ${classnameDefaults([
                submitPurchaseRequestData.loading,
                "disabled",
              ])}`}
            >
              {submitPurchaseRequestData.loading
                ? "Procesando..."
                : "Continuar"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CheckoutStepper;
