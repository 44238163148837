import { createSelector } from 'reselect';

export const getAuth = (state) => state.auth;

export const getAuthToken = createSelector(
  [getAuth],
  ({ authToken }) => authToken
);
export const getUser = createSelector([getAuth], ({ user }) => user);

export const getAuthCheckDone = createSelector(
  [getAuth],
  ({ authCheckDone }) => authCheckDone
);

export const getLoginRequestData = createSelector(
  [getAuth],
  ({ loginRequestData }) => loginRequestData
);

export const getLogoutRequestData = createSelector(
  [getAuth],
  ({ logoutRequestData }) => logoutRequestData
);

export const getRegisterRequestData = createSelector(
  [getAuth],
  ({ registerRequestData }) => registerRequestData
);

export const getRecoverPasswordRequestData = createSelector(
  [getAuth],
  ({ recoverPasswordRequestData }) => recoverPasswordRequestData
);

export const getNewPasswordRequestData = createSelector(
  [getAuth],
  ({ getNewPasswordRequestData }) => getNewPasswordRequestData
);
