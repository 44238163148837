import { useState, useEffect } from 'react';
import PageLayout from 'components/common/PageLayout';
import Header from 'components/Header';
import Footer from 'components/Footer';
import { routes } from 'routing/routes';
import { Link, useLocation } from 'react-router-dom';
import { classnameDefaults } from 'utils/common';
import { useTitle } from 'react-use';

const initialFormState = {
  username: '',
};

const RecoverPassword = ({
  recoverPasswordRequestData,
  recoverPassword,
  clearRecoverPassword,
}) => {
  useTitle(`Recuperar Contraseña | ${process.env.REACT_APP_PAGE_TITLE}`);

  const [formData, setFormData] = useState(initialFormState);
  const location = useLocation();

  useEffect(() => {
    clearRecoverPassword();
  }, [location, clearRecoverPassword]);

  const fieldHandler = (field, e) => {
    const value = e.target.value;
    setFormData({
      ...formData,
      [field]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    recoverPassword(formData);
  };

  return (
    <>
      <PageLayout>
        <Header />
        <main className='main-login'>
          <div className='wrapper'>
            <h2 className='page-title'>Recuperar contraseña</h2>

            {recoverPasswordRequestData.done &&
            !recoverPasswordRequestData.error ? (
              <>
                <p>¡Link de recuperación enviado!</p>

                <p>
                  Te hemos enviado un mail para que puedas recuperar la
                  contraseña.
                </p>

                <Link to={routes.base.path}>Volver al inicio</Link>
              </>
            ) : (
              <>
                <p>
                  Ingresá tu usuario o email y te enviaremos un link para que
                  puedas reestablecer tu contraseña.
                </p>

                <form onSubmit={handleSubmit}>
                  <label className='label'>Usuario o email</label>
                  <input
                    type='text'
                    name='username'
                    required
                    className='input'
                    value={formData['username']}
                    onChange={(e) => fieldHandler('username', e)}
                  />

                  <button
                    disabled={recoverPasswordRequestData.loading}
                    className={`button ${classnameDefaults([
                      recoverPasswordRequestData.loading,
                      'disabled',
                    ])}`}
                  >
                    {!recoverPasswordRequestData.loading ? (
                      'Recuperar contraseña'
                    ) : (
                      <>
                        <span>Enviando link</span>{' '}
                        <i className='fas fa-spinner fa-pulse'></i>
                      </>
                    )}
                  </button>
                </form>

                <p className='register-label'>
                  <Link to={routes.login.path}>Volver atrás</Link>
                </p>
              </>
            )}
          </div>
        </main>
      </PageLayout>
      <Footer />
    </>
  );
};

export default RecoverPassword;
