import Home from './Home';
import { connect } from 'react-redux';
import { getAuthToken } from 'store/redux/auth/selectors';

const mapStateToProps = (state) => ({
  authToken: getAuthToken(state),
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
