import NewPassword from './NewPassword';
import { connect } from 'react-redux';
import { getNewPassword } from 'store/redux/auth/actionCreators';
import { getNewPasswordRequestData } from 'store/redux/auth/selectors';

const mapStateToProps = (state) => ({
  getNewPasswordRequestData: getNewPasswordRequestData(state),
});

const mapDispatchToProps = {
  getNewPassword,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewPassword);
