import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { routes } from 'routing/routes';
import StoreBase from './StoreBase/StoreBase';
import StoreCategory from './StoreCategory/StoreCategory';
import StoreProduct from './StoreProduct';
import StoreSubcategory from './StoreSubcategory/StoreSubcategory';

const Store = ({ authToken }) => {
  return (
    <Switch>
      <Route exact path={routes.tienda.base.path}>
        <StoreBase />
      </Route>
      <Route exact path={routes.tienda.category.path}>
        <StoreCategory />
      </Route>
      <Route exact path={routes.tienda.subcategory.path}>
        <StoreSubcategory authToken={authToken} />
      </Route>
      <Route exact path={routes.tienda.product.path}>
        <StoreProduct authToken={authToken} />
      </Route>
    </Switch>
  );
};

export default Store;
