import PageLayout from "components/common/PageLayout";
import Header from "components/Header";
import Footer from "components/Footer";
import { Link, useLocation } from "react-router-dom";
import { routes } from "routing/routes";
import { useTitle } from "react-use";

const CompraFinalizada = () => {
  useTitle(`Compra Finalizada | ${process.env.REACT_APP_PAGE_TITLE}`);

  const location = useLocation();

  const purchaseStatus = new URLSearchParams(location.search).get("status");
  const purchaseCode = new URLSearchParams(location.search)
    .get("external_reference")
    ?.replace("Ref_", "");

  const getContent = () => {
    if (location.state?.type) {
      return (
        <main className="main-success">
          <div className="wrapper">
            <h1>
              <i className="fas fa-check-circle"></i> ¡Su pedido de compra ha
              sido cargado correctamente!
            </h1>

            <p>
              Su código de compra es <b>{location.state.code}</b>. Puede ver el
              estado de su compra en cualquier momento ingresando en{" "}
              <Link to={routes.purchaseStatus.path}>este link</Link>.
            </p>

            <p>
              Le hemos enviado a su correo con el detalle de su compra y los
              datos para realizar la transferencia/depósito. En el caso de no
              recibir el mail por favor contactese con nosotros para que le
              brindemos la información para pagar.
            </p>

            <p>
              Una vez que recibamos y verifiquemos el comprobante del pago, nos
              contactaremos con usted a la brevedad para coordinar el envío.
            </p>

            <Link to={routes.base.path}>Volver al inicio</Link>
          </div>
        </main>
      );
    }

    if (purchaseStatus === "pending") {
      return (
        <main class="main-pending">
          <div class="wrapper">
            <h1>
              <i class="fas fa-check-circle"></i> ¡Su pago se encuentra en
              estado pendiente!
            </h1>

            <p>
              Su código de compra es <b>{purchaseCode}</b>. Puede ver el estado
              de su compra en cualquier momento ingresando en{" "}
              <Link to={routes.purchaseStatus.path}>este link</Link>.
            </p>

            <p>Le hemos enviado a su correo con el detalle de su compra.</p>

            <p>
              Una vez que el pago se haya acreditado nos contactaremos con usted
              para coordinar el envío.
            </p>

            <Link to={routes.base.path}>Volver al inicio</Link>
          </div>
        </main>
      );
    }

    if (purchaseStatus === "approved") {
      return (
        <main class="main-success">
          <div class="wrapper">
            <h1>
              <i class="fas fa-check-circle"></i> ¡Su pago se ha acreditado
              correctamente!
            </h1>

            <p>
              Su código de compra es <b>{purchaseCode}</b>. Puede ver el estado
              de su compra en cualquier momento ingresando en{" "}
              <Link to={routes.purchaseStatus.path}>este link</Link>.
            </p>

            <p>Le hemos enviado a su correo con el detalle de su compra.</p>

            <p>
              Nos contactaremos con usted a la brevedad para coordinar el envío.
            </p>

            <Link to={routes.base.path}>Volver al inicio</Link>
          </div>
        </main>
      );
    }

    return null;
  };

  return (
    <>
      <PageLayout>
        <Header />
        {getContent()}
      </PageLayout>
      <Footer />
    </>
  );
};

export default CompraFinalizada;
