import { routes } from "routing/routes";
import { NavLink, Link, useHistory } from "react-router-dom";
import LogoImage from "assets/images/logo.png";
import { useEffect, useState } from "react";
import api from "store/api";
import ENDPOINTS from "constants/endpoints";
import { classnameDefaults } from "utils/common";
import search from "../../assets/images/buscar.png";
import { useRef } from "react";

const Header = ({ user, cartItemsAmountLabels, logout }) => {
  /*INPUT SEARCH*/
  const history = useHistory();
  const inputRef = useRef();

  /*input search*/
  const [categories, setCategories] = useState([]);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const [topHeaderHidden, setTopHeaderHidden] = useState(
    localStorage.getItem("top-header-hidden") || false
  );

  const handleChange = (e) => {
    const { value, name } = e;
  };

  const hideTopHeaderHandler = () => {
    localStorage.setItem("top-header-hidden", true);
    setTopHeaderHidden(true);
  };

  const processCategories = (data) =>
    data
      .filter((item) => item.parent == null)
      .map((item) => ({
        ...item,
        subcategories: data.filter((subcat) => subcat.parent === item.id),
      }));

  useEffect(() => {
    const fetchHeaderCategories = async () => {
      try {
        const response = await api({
          method: "get",
          url: ENDPOINTS.getCategories(),
        });

        const processedCategories = processCategories(response.data.data);
        setCategories(processedCategories);
      } catch (err) {}
    };

    fetchHeaderCategories();
  }, []);

  const handleSearch = (e) => {
    e.preventDefault();
    const value = inputRef.current.value.trim();
    if (value.length === 0) return;
    history.push(`/buscar?query=${value}`);

    console.log(value);
  };

  const userActionsContainer = (
    <div className="user-actions-container">
      {user ? (
        <div className="login-dead-container">
          <div className="login-dead">
            <i className="fas fa-user"></i>
            {user.username}
          </div>
          <button className="logout" onClick={logout}>
            Cerrar sesión
          </button>
        </div>
      ) : (
        <div className="user-details">
          {!user && (
            <NavLink
              className="user-max-details register"
              to={routes.registro.path}
            >
              Registrate
            </NavLink>
          )}
          <Link to={routes.login.path} className="login user-max-details">
            {/*  <i className="fas fa-user"></i> <span>Ingresar</span> */}
            Ingresar
          </Link>
          {/* <Link class="user-max-details shop">Compras comunitarias</Link> */}
        </div>
      )}

      <NavLink
        to={routes.checkout.path}
        className="checkout checkout-responsive user-max-details"
      >
        {/*  <i className="fas fa-shopping-cart"></i> */} Carrito
        {cartItemsAmountLabels}
      </NavLink>
    </div>
  );

  return (
    <>
      {!topHeaderHidden && (
        <div class="top-header">
          <div class="wrapper">
            <p>
              Como utilizamos mercado pago para los cobros digitales, elegí como
              abonarlo vos.
            </p>

            <i class="fas fa-times" onClick={hideTopHeaderHandler}></i>
          </div>
        </div>
      )}
      <header className={classnameDefaults([mobileMenuOpen, "active"])}>
        <div className="wrapper header-fix-search">
          <div className="header-right">
            <div>
              <button
                className="pancake-menu"
                onClick={() => setMobileMenuOpen((prev) => !prev)}
              >
                <div className="pm-lvl-1">
                  <div className="pm-lvl-2">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </div>
                {/* <span>Menu</span> */}
              </button>
            </div>
            <div>
              <Link to={routes.base.path} className="logo">
                <img src={LogoImage} alt="Logotipo" />
              </Link>
            </div>
          </div>

          <div className="input-search">
            <form onSubmit={handleSearch}>
              <button>
                <img className="logo-search" src={search} />
              </button>
              <input
                className="middle-header"
                type="text"
                placeholder="Encontrá tus productos.."
                ref={inputRef}
                name="name"
              />
            </form>
          </div>

          <nav className={classnameDefaults([mobileMenuOpen, "active"])}>
            <ul>
              <li>
                <NavLink exact to={routes.base.path}>
                  Inicio
                </NavLink>
              </li>
              <li>
                <NavLink to={routes.nosotros.path}>Quienes Somos</NavLink>
              </li>
              <li>
                <NavLink to={routes.tienda.base.path}>
                  Productos <i className="fas fa-angle-down"></i>
                </NavLink>
                <ul className="categories-container">
                  {categories.map((item) => (
                    <li key={item.id}>
                      <Link to={`${routes.tienda.base.path}/${item.permalink}`}>
                        {item.title}
                      </Link>
                      {item.subcategories.length > 0 && (
                        <ul>
                          {item.subcategories.map((subcategory) => (
                            <li key={subcategory.id}>
                              <Link
                                to={`${routes.tienda.base.path}/${item.permalink}/${subcategory.permalink}`}
                              >
                                {subcategory.title}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      )}
                    </li>
                  ))}
                </ul>
              </li>

              <li>
                <NavLink to={routes.contacto.path}>Contacto</NavLink>
              </li>
            </ul>
            {userActionsContainer}
          </nav>
          <div className="user-actions">{userActionsContainer}</div>
        </div>
      </header>
    </>
  );
};

export default Header;
