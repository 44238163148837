import PageLayout from "components/common/PageLayout";
import Header from "components/Header";
import Footer from "components/Footer";
import { useParams } from "react-router-dom";
import useFetch from "hooks/useFetch";
import ENDPOINTS from "constants/endpoints";
import { routes } from "routing/routes";
import ErrorMessage from "components/common/ErrorMessage";
import StoreCard from "components/common/StoreCard/StoreCard";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import { useTitle } from "react-use";
import ScrollToTop from "components/ScrollToTop";

const StoreCategory = () => {
  const params = useParams();

  const [error, loading, done, data] = useFetch(
    {
      method: "get",
      url: ENDPOINTS.getSubcategories(),
      params: { category: params.category },
    },
    "Error al cargar las categorías, actualizar la pagina para reintentar.",
    [params.category]
  );

  const breadcrumb = (data) => {
    const { category } = data;
    return [
      {
        link: storePath,
        label: "Tienda",
      },
      {
        link: `${storePath}/${category.permalink}`,
        label: category.title,
      },
    ];
  };

  const storePath = routes.tienda.base.path;

  useTitle(`${data?.category?.title} | ${process.env.REACT_APP_PAGE_TITLE}`);

  return (
    <>
      <PageLayout>
        <ScrollToTop />
        <Header />

        <main>
          <div className="wrapper">
            {loading && (
              <div className="store-loading">
                <i className="fas fa-spinner fa-pulse"></i>
                <span>Cargando</span>
              </div>
            )}
            {!!error && (
              <ErrorMessage
                message={error}
                linkLabel="Volver a la tienda"
                link={storePath}
              />
            )}
            {done && !error && (
              <>
                <h1 class="nomargin">Nuestros productos</h1>
                <Breadcrumb data={breadcrumb(data)} />
                <div className="store-grid">
                  {data.subcategories.map((item) => (
                    <StoreCard
                      imagePath={process.env.REACT_APP_CATEGORY_IMAGE_PATH}
                      key={item.id}
                      href={`${storePath}/${data.category.permalink}/${item.permalink}`}
                      image={item.image}
                      title={item.title}
                      isProduct={false}
                    />
                  ))}
                </div>
              </>
            )}
          </div>
        </main>
      </PageLayout>
      <Footer />
    </>
  );
};

export default StoreCategory;
