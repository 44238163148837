import * as actionTypes from 'store/redux/auth/actionTypes';
import * as selectors from 'store/redux/auth/selectors';
import api from 'store/api';
import ENDPOINTS from 'constants/endpoints';
import {
  setStore,
  processActionCreatorSetters,
  processActionCreatorStatusFunctions,
  objectToFormData,
  fireError,
  fireSuccess,
} from 'utils/common';
import { setters as cartSetters } from 'store/redux/cart/actionCreators';
import { routes } from 'routing/routes';

const settersArray = [
  ['clearState', actionTypes.CLEAR_STATE],
  ['setUser', actionTypes.SET_USER],
  ['setAuthCheckDone', actionTypes.SET_AUTH_CHECK_DONE],
  ['clearRegister', actionTypes.CLEAR_REGISTER],
  ['clearRecoverPassword', actionTypes.CLEAR_RECOVER_PASSWORD],
];

const actionCreatorsStatusArray = [
  ['login', actionTypes.LOGIN],
  ['logout', actionTypes.LOGOUT],
  ['register', actionTypes.REGISTER],
  ['recoverPassword', actionTypes.RECOVER_PASSWORD],
  ['getNewPassword', actionTypes.GET_NEW_PASSWORD],
];

export const setters = processActionCreatorSetters(settersArray);
export const statuses = processActionCreatorStatusFunctions(
  actionCreatorsStatusArray
);

setters.setAuthToken = (token) => {
  localStorage.setItem('auth_token', token);
  return setStore(actionTypes.SET_AUTH_TOKEN, token);
};

setters.removeAuthToken = () => {
  localStorage.removeItem('auth_token');
  return setStore(actionTypes.REMOVE_AUTH_TOKEN);
};

export const clearRegister = () => setters.clearRegister();

export const clearRecoverPassword = () => setters.clearRecoverPassword();

export const authStatusCheck = () => async (dispatch, getState) => {
  const appState = getState();
  const authToken = selectors.getAuthToken(appState);

  try {
    if (!authToken) {
      const response = await api({
        method: 'post',
        url: ENDPOINTS.createGuestToken(),
      });

      dispatch(setters.setAuthToken(response.data));
    } else {
      const response = await api({
        method: 'get',
        url: ENDPOINTS.getTokenEntity(),
        params: { token: authToken },
      });

      if (!response.data.status && response.data.code === 1) {
        dispatch(setters.removeAuthToken());
        dispatch(authStatusCheck());
        return;
      }

      if (response.data.status && response.data)
        dispatch(setters.setUser(response.data.data));
    }

    const storeAuthToken = selectors.getAuthToken(getState());
    const cartResponse = await api({
      method: 'get',
      url: ENDPOINTS.getCart(),
      params: { token: storeAuthToken },
    });

    await api({
      method: 'post',
      url: ENDPOINTS.checkPurchaseStatus(),
      data: objectToFormData({ token: storeAuthToken }),
    });

    dispatch(cartSetters.setCart(cartResponse.data));
    dispatch(setters.setAuthCheckDone(true));
  } catch (err) {
    dispatch(setters.removeAuthToken());
    dispatch(setters.setAuthCheckDone(true));
  }
};

export const login = (username, password) => async (dispatch, getState) => {
  dispatch(statuses.loginLoading());

  const token = selectors.getAuthToken(getState());

  try {
    const response = await api({
      method: 'post',
      url: ENDPOINTS.login(),
      data: objectToFormData({
        username,
        password,
        token,
      }),
    });

    if (!response.data.status) {
      fireError('Error al iniciar sesión...', response.data.message);
      dispatch(statuses.loginError());
      return;
    }

    dispatch(setters.setAuthToken(response.data.data));
    window.location.reload();

    dispatch(statuses.loginSuccess());
  } catch (error) {
    dispatch(statuses.loginError());
  }
};

export const logout = () => async (dispatch, getState) => {
  dispatch(statuses.logoutLoading());

  const token = selectors.getAuthToken(getState());

  try {
    await api({
      method: 'post',
      url: ENDPOINTS.logout(),
      data: objectToFormData({
        token,
      }),
    });

    window.location.reload();
    dispatch(setters.removeAuthToken());

    dispatch(statuses.logoutSuccess());
  } catch (error) {
    dispatch(statuses.logoutError());
  }
};

export const register = (formData) => async (dispatch, getState) => {
  dispatch(statuses.registerLoading());

  try {
    const response = await api({
      method: 'post',
      url: ENDPOINTS.register(),
      data: objectToFormData(formData),
    });

    if (!response.data.status) {
      fireError('Error al registrarse...', response.data.message);
      dispatch(statuses.registerError());
      return;
    }

    dispatch(statuses.registerSuccess());
  } catch (error) {
    dispatch(statuses.registerError());
  }
};

export const recoverPassword = (formData) => async (dispatch, getState) => {
  dispatch(statuses.recoverPasswordLoading());

  try {
    const response = await api({
      method: 'post',
      url: ENDPOINTS.recoverPassword(),
      data: objectToFormData(formData),
    });

    if (!response.data.status) {
      fireError('Error al recuperar contraseña...', response.data.message);
      dispatch(statuses.recoverPasswordError());
      return;
    }

    dispatch(statuses.recoverPasswordSuccess());
  } catch (error) {
    dispatch(statuses.recoverPasswordError());
  }
};

export const getNewPassword =
  (formData, history) => async (dispatch, getState) => {
    dispatch(statuses.getNewPasswordLoading());

    try {
      const response = await api({
        method: 'post',
        url: ENDPOINTS.getNewPassword(),
        data: objectToFormData(formData),
      });

      if (!response.data.status) {
        fireError('Error al cambiar contraseña...', response.data.message);
        dispatch(statuses.getNewPasswordError());
        return;
      }

      await fireSuccess('¡Contraseña actualizada correctamente!');
      history.push(routes.login.path);

      dispatch(statuses.getNewPasswordSuccess());
    } catch (error) {
      dispatch(statuses.getNewPasswordError());
    }
  };
