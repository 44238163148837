const MetodosEnvio = () => {
  return (
    <div>
      <p>
        Enviamos a todo el país por Correo Argentino o transporte. Los pedidos
        se entregan o despachan el día viernes (en caso de que sea feriado se
        realizará el día anterior).{" "}
      </p>
      <a>Ver mapa para envíos y sus costos.</a>

      <p>Coordinaremos la entrega en un horario adecuado para vos.</p>
    </div>
  );
};

export default MetodosEnvio;
