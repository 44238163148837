import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { routes } from 'routing/routes';
import { getUser } from 'store/redux/auth/selectors';

const UnloggedRoute = ({ user, component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      !user ? <Component {...props} /> : <Redirect to={routes.base.path} />
    }
  />
);

const mapStateToProps = (state) => ({
  user: getUser(state),
});

export default connect(mapStateToProps)(UnloggedRoute);
