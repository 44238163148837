import { applyMiddleware, combineReducers, createStore } from 'redux';
import thunk from 'redux-thunk';

import authReducer from 'store/redux/auth/reducer';
import cartReducer from 'store/redux/cart/reducer';

const reducers = combineReducers({
  auth: authReducer,
  cart: cartReducer,
});

const store = createStore(reducers, applyMiddleware(thunk));

export default store;
