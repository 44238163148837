const ComprasComunitarias = () => {
  return (
    <p>
      Si elegís compras en grupo accedés a un descuento especial (es
      imprescindible que te registres). Enganchate con un familiar o amigxs.
      Ahorrás y te alimentás mejor!
    </p>
  );
};

export default ComprasComunitarias;
