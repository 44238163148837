import PageLayout from 'components/common/PageLayout';
import Header from 'components/Header';
import Footer from 'components/Footer';
import { useParams } from 'react-router-dom';
import useFetch from 'hooks/useFetch';
import ENDPOINTS from 'constants/endpoints';
import { routes } from 'routing/routes';
import ErrorMessage from 'components/common/ErrorMessage';
import StoreCard from 'components/common/StoreCard/StoreCard';
import Breadcrumb from '../Breadcrumb/Breadcrumb';
import { useTitle } from 'react-use';

const StoreSubcategory = ({ authToken }) => {
  const params = useParams();

  const [error, loading, done, data] = useFetch(
    {
      method: 'get',
      url: ENDPOINTS.getProducts(),
      params: {
        category: params.category,
        subcategory: params.subcategory,
        token: authToken,
      },
    },
    'Error al cargar los productos, actualizar la pagina para reintentar.',
    [params.category, params.subcategory]
  );

  const breadcrumb = (data) => {
    const { category, subcategory } = data;
    return [
      {
        link: storePath,
        label: 'Tienda',
      },
      {
        link: `${storePath}/${category.permalink}`,
        label: category.title,
      },
      {
        link: `${storePath}/${category.permalink}/${subcategory.permalink}`,
        label: subcategory.title,
      },
    ];
  };

  const storePath = routes.tienda.base.path;

  useTitle(
    `${data?.category?.title} - ${data?.subcategory?.title} | ${process.env.REACT_APP_PAGE_TITLE}`
  );

  return (
    <>
      <PageLayout>
        <Header />

        <main>
          <div className='wrapper'>
            {loading && (
              <div className='store-loading'>
                <i className='fas fa-spinner fa-pulse'></i>
                <span>Cargando</span>
              </div>
            )}
            {!!error && (
              <ErrorMessage
                message={error}
                linkLabel='Volver a la tienda'
                link={storePath}
              />
            )}
            {done && !error && (
              <>
                <h1 class='nomargin'>Nuestros productos</h1>
                <Breadcrumb data={breadcrumb(data)} />
                {data.products.length > 0 ? (
                  <div className='store-grid'>
                    {data.products.map((item) => (
                      <StoreCard
                        imagePath={
                          process.env.REACT_APP_PRODUCT_THUMBNAIL_IMAGE_PATH
                        }
                        href={`${storePath}/${data.category.permalink}/${data.subcategory.permalink}/${item.permalink}`}
                        key={item.id}
                        image={item.image.filename}
                        title={item.title}
                        isProduct={true}
                        product={item}
                      />
                    ))}
                  </div>
                ) : (
                  <div class='zero-products'>
                    <i class='fas fa-box-open'></i>
                    <p>Esta categoría todavía no tiene productos cargados.</p>
                  </div>
                )}
              </>
            )}
          </div>
        </main>
      </PageLayout>
      <Footer />
    </>
  );
};

export default StoreSubcategory;
