import RecoverPassword from './RecoverPassword';
import { connect } from 'react-redux';
import {
  recoverPassword,
  clearRecoverPassword,
} from 'store/redux/auth/actionCreators';
import { getRecoverPasswordRequestData } from 'store/redux/auth/selectors';

const mapStateToProps = (state) => ({
  recoverPasswordRequestData: getRecoverPasswordRequestData(state),
});

const mapDispatchToProps = {
  recoverPassword,
  clearRecoverPassword,
};

export default connect(mapStateToProps, mapDispatchToProps)(RecoverPassword);
