import StoreProduct from './StoreProduct';
import { connect } from 'react-redux';
import { getUser } from 'store/redux/auth/selectors';
import { addToCart } from 'store/redux/cart/actionCreators';
import { getAddToCartRequestData } from 'store/redux/cart/selectors';

const mapStateToProps = (state) => ({
  user: getUser(state),
  addToCartRequestData: getAddToCartRequestData(state),
});

const mapDispatchToProps = {
  addToCart,
};

export default connect(mapStateToProps, mapDispatchToProps)(StoreProduct);
