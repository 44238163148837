import { reducerMatch } from 'utils/common';
import REDUCER_TYPES from 'constants/reducerTypes';
import REQUEST_FIELDS from 'constants/requestFields';
import * as actionTypes from 'store/redux/auth/actionTypes';

const initialState = {
  authToken: localStorage.getItem('auth_token') || null,
  authCheckDone: false,
  user: null,
  loginRequestData: { ...REQUEST_FIELDS },
  logoutRequestData: { ...REQUEST_FIELDS },
  registerRequestData: { ...REQUEST_FIELDS },
  recoverPasswordRequestData: { ...REQUEST_FIELDS },
  getNewPasswordRequestData: { ...REQUEST_FIELDS },
};

const reducers = [
  [REDUCER_TYPES.SETTER, actionTypes.SET_AUTH_CHECK_DONE, 'authCheckDone'],
  [REDUCER_TYPES.SETTER, actionTypes.SET_USER, 'user'],
  [REDUCER_TYPES.SETTER, actionTypes.SET_AUTH_TOKEN, 'authToken'],
  [REDUCER_TYPES.SETTER, actionTypes.REMOVE_AUTH_TOKEN, 'authToken', null],
  [REDUCER_TYPES.REQUEST_DATA, actionTypes.LOGIN, 'loginRequestData'],
  [REDUCER_TYPES.REQUEST_DATA, actionTypes.REGISTER, 'registerRequestData'],
  [
    REDUCER_TYPES.REQUEST_DATA,
    actionTypes.RECOVER_PASSWORD,
    'recoverPasswordRequestData',
  ],
  [
    REDUCER_TYPES.REQUEST_DATA,
    actionTypes.GET_NEW_PASSWORD,
    'getNewPasswordRequestData',
  ],
];

const reducer = (state = initialState, { type, payload }) => {
  const match = reducerMatch(type, payload, reducers, state);
  if (match) return match;

  switch (type) {
    case actionTypes.CLEAR_REGISTER: {
      return {
        ...state,
        registerRequestData: { ...REQUEST_FIELDS },
      };
    }
    case actionTypes.CLEAR_RECOVER_PASSWORD: {
      return {
        ...state,
        recoverPasswordRequestData: { ...REQUEST_FIELDS },
      };
    }
    case actionTypes.CLEAR_STATE:
      return { ...initialState };
    default:
      return state;
  }
};

export default reducer;
