import AppContainer from './AppContainer';
import { connect } from 'react-redux';
import { authStatusCheck } from 'store/redux/auth/actionCreators';
import { getAuthCheckDone } from 'store/redux/auth/selectors';

const mapStateToProps = (state) => ({
  authCheckDone: getAuthCheckDone(state),
});

const mapDispatchToProps = {
  authStatusCheck,
};

export default connect(mapStateToProps, mapDispatchToProps)(AppContainer);
