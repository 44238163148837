import { Link } from 'react-router-dom';

const ErrorMessage = ({ message, linkLabel, link }) => {
  return (
    <>
      <h3 className='error-message-title'>Error</h3>
      <p className='error-message'>
        <i className='fa fa-exclamation-triangle'></i>
        <span>{message}</span>
      </p>
      {link && linkLabel && (
        <Link to={link} className='error-message-link'>
          <i className='fa fa-arrow-left'></i>
          {linkLabel}
        </Link>
      )}
    </>
  );
};

export default ErrorMessage;
