import { Link } from 'react-router-dom';
import { routes } from 'routing/routes';

const NoData = () => {
  return (
    <div className='zero-data'>
      <h1>Su carrito se encuentra vacío...</h1>
      <p>
        Cuando añadas algún producto al carrito podrás verlos aquí.{' '}
        <Link to={routes.tienda.base.path}>Ir a la tienda.</Link>
      </p>
    </div>
  );
};

export default NoData;
