const ComoPagar = () => {
  return (
    <p>
      Podés realizar el pago en efectivo, tarjetas de crédito, débito o
      transferencias en tu carro de compra al terminar la misma.
    </p>
  );
};

export default ComoPagar;
