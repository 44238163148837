import axios from 'axios';

// const getHeaders = () => ({ 'Content-Type': 'application/json' });

const api = ({ headers, method, ...rest }) => {
  const axiosObj = {
    headers: headers || {},
    method,
    ...rest,
  };

  return axios(axiosObj);
};

export default api;
