import endpoints from "constants/endpoints";
import { useEffect, useState } from "react";
import { Redirect, useLocation } from "react-router-dom";
import { routes } from "routing/routes";
import api from "store/api";
import { objectToFormData } from "utils/common";
import PageLayout from "./common/PageLayout";
import Footer from "./Footer";
import Header from "./Header";
import StoreCard from "./common/StoreCard/StoreCard";
import { useSelector } from "react-redux";
import { getAuthToken } from "store/redux/auth/selectors";

/*4 PASOS */

export const Search = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const query = params.get("query");

  const token = useSelector(getAuthToken);

  const [products, setProducts] = useState([]);
  console.log(products);

  useEffect(() => {
    async function fetchProducts() {
      const response = await api({
        method: "POST",
        url: endpoints.search(),
        data: objectToFormData({ query, token }),
      });

      const results = Object.values(response.data?.results) ?? [];

      setProducts(results);
    }

    fetchProducts();
  }, [query]);

  if (!query) {
    return <Redirect to={routes.base.path} />;
  }
  /* ((products.length === 0)) ? (component que diga que no se encontraron resultados) : 
   HACE CONDICIONAL QUE SI PRODUCTS.LENGHT = 0 MENSAJE QUE DIGA QUE NO HAY NADA DE ESO
    
  }*/

  return (
    <>
      <PageLayout>
        <Header />
        <div>
          {products.length === 0 ? (
            <div className="noproducts-find">
              <h1>
                No existe el producto. Vuelva a intentarlo buscando con otro
                nombre.
              </h1>
            </div>
          ) : (
            <div className="product-grid">
              {products.map((product, index) => (
                <StoreCard
                  index={index}
                  imagePath={process.env.REACT_APP_PRODUCT_THUMBNAIL_IMAGE_PATH}
                  key={product.id}
                  image={product.image.filename}
                  title={product.title}
                  isProduct={true}
                  product={product}
                />
              ))}
            </div>
          )}
        </div>
      </PageLayout>
      <Footer />
    </>
  );
};

export default Search;
