import { useState, useEffect } from 'react';
import PageLayout from 'components/common/PageLayout';
import Header from 'components/Header';
import Footer from 'components/Footer';
import { routes } from 'routing/routes';
import { Link, Redirect, useHistory, useLocation } from 'react-router-dom';
import { classnameDefaults } from 'utils/common';
import { useTitle } from 'react-use';

const initialFormState = {
  password: '',
};

const NewPassword = ({ getNewPasswordRequestData, getNewPassword }) => {
  useTitle(`Nueva Contraseña | ${process.env.REACT_APP_PAGE_TITLE}`);

  const [formData, setFormData] = useState(initialFormState);
  const location = useLocation();
  const history = useHistory();
  const token = new URLSearchParams(location.search).get('token');

  if (!token) return <Redirect to={routes.base.path} />;

  const fieldHandler = (field, e) => {
    const value = e.target.value;
    setFormData({
      ...formData,
      [field]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    getNewPassword({ ...formData, token }, history);
  };

  return (
    <>
      <PageLayout>
        <Header />
        <main className='main-login'>
          <div className='wrapper'>
            <h2 className='page-title'>Establecer contraseña nueva</h2>

            <p>Ingresá tu contraseña nueva.</p>

            <form onSubmit={handleSubmit}>
              <label className='label'>Contraseña</label>
              <input
                type='password'
                name='password'
                required
                minLength='6'
                className='input'
                value={formData['password']}
                onChange={(e) => fieldHandler('password', e)}
              />

              <button
                disabled={getNewPasswordRequestData.loading}
                className={`gold-button ${classnameDefaults([
                  getNewPasswordRequestData.loading,
                  'disabled',
                ])}`}
              >
                {!getNewPasswordRequestData.loading ? (
                  'Cambiar contraseña'
                ) : (
                  <>
                    <span>Cambiando contraseña</span>{' '}
                    <i className='fas fa-spinner fa-pulse'></i>
                  </>
                )}
              </button>
            </form>

            <p className='register-label'>
              <Link to={routes.login.path}>Volver atrás</Link>
            </p>
          </div>
        </main>
      </PageLayout>
      <Footer />
    </>
  );
};

export default NewPassword;
