import { useState } from "react";
import PageLayout from "components/common/PageLayout";
import Header from "components/Header";
import Footer from "components/Footer";
import { classnameDefaults } from "utils/common";
import { Link } from "react-router-dom";
import { routes } from "routing/routes";
import { useTitle } from "react-use";

const initialFormState = {
  nombre: "",
  telefono: "",
  email: "",
  mensaje: "",
};

const Contacto = ({ sendMessageRequestData, sendMessage }) => {
  const [formData, setFormData] = useState(initialFormState);
  useTitle(`Contacto | ${process.env.REACT_APP_PAGE_TITLE}`);

  const fieldHandler = (field, e) => {
    const value = e.target.value;
    setFormData({
      ...formData,
      [field]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    sendMessage(formData);
  };

  return (
    <>
      <PageLayout>
        <Header />
        <main className="main-contacto">
          <div>
            <div className="cols">
              <div className="col col-1 wrapper">
                <h2 className="page-title contact-tittle">Contacto</h2>
                <form onSubmit={handleSubmit}>
                  <div className="form-contact-first">
                    <input
                      type="text"
                      name="nombre"
                      required
                      className="input"
                      value={formData["nombre"]}
                      onChange={(e) => fieldHandler("nombre", e)}
                      placeholder="Nombre"
                    />

                    <input
                      type="tel"
                      name="telefono"
                      required
                      className="input"
                      value={formData["telefono"]}
                      onChange={(e) => fieldHandler("telefono", e)}
                      placeholder="Teléfono"
                    />

                    <input
                      type="email"
                      name="email"
                      required
                      className="input"
                      value={formData["email"]}
                      onChange={(e) => fieldHandler("email", e)}
                      placeholder="Email"
                    />
                  </div>

                  <div>
                    <textarea
                      name="mensaje"
                      required
                      className="input"
                      value={formData["mensaje"]}
                      onChange={(e) => fieldHandler("mensaje", e)}
                      placeholder="Mensaje"
                    ></textarea>
                  </div>
                  <div className="button-section-contact">
                    <button
                      disabled={sendMessageRequestData.loading}
                      className={`button ${classnameDefaults([
                        sendMessageRequestData.loading,
                        "disabled",
                      ])}`}
                    >
                      {!sendMessageRequestData.loading ? (
                        "Enviar mensaje"
                      ) : (
                        <>
                          <span>Enviando</span>{" "}
                          <i className="fas fa-spinner fa-pulse"></i>
                        </>
                      )}
                    </button>
                  </div>
                </form>
              </div>
              <div className="col col-2">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3281.1869334908774!2d-58.699492584501776!3d-34.67523126879304!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bcc07c800e49e1%3A0x7b3789ee63d6e662!2sJuan%20Bautista%20Alberdi%201637%2C%20B1718FBY%20San%20Antonio%20de%20Padua%2C%20Provincia%20de%20Buenos%20Aires!5e0!3m2!1ses-419!2sar!4v1677082094750!5m2!1ses-419!2sar"
                  width="100%"
                  height="300"
                  style={{ border: "0" }}
                  allowfullscreen
                  loading="lazy"
                ></iframe>
              </div>
            </div>
          </div>
        </main>
      </PageLayout>
      <Footer />
    </>
  );
};

export default Contacto;
