import PageLayout from "components/common/PageLayout";
import Header from "components/Header";
import Footer from "components/Footer";
import useFetch from "hooks/useFetch";
import ENDPOINTS from "constants/endpoints";
import { routes } from "routing/routes";
import Breadcrumb from "components/Store/Breadcrumb/Breadcrumb";
import StoreCard from "components/common/StoreCard/StoreCard";
import { useTitle } from "react-use";
import ScrollToTop from "components/ScrollToTop";

const StoreBase = () => {
  useTitle(`Tienda | ${process.env.REACT_APP_PAGE_TITLE}`);

  const [error, loading, done, categories] = useFetch(
    {
      method: "get",
      url: ENDPOINTS.getCategories(),
      params: { only_top_level: true },
    },
    "Error al cargar las categorías, actualizar la pagina para reintentar."
  );

  const storePath = routes.tienda.base.path;

  return (
    <>
      <PageLayout>
        <ScrollToTop />
        <Header />
        <main>
          <div className="wrapper">
            {loading && (
              <div className="store-loading">
                <i className="fas fa-spinner fa-pulse"></i>
                <span>Cargando</span>
              </div>
            )}

            {done && !error && (
              <>
                <h1 class="nomargin">Nuestros productos</h1>
                <Breadcrumb data={[{ link: storePath, label: "Tienda" }]} />
                <div className="store-grid">
                  {categories.map((item) => (
                    <StoreCard
                      imagePath={process.env.REACT_APP_CATEGORY_IMAGE_PATH}
                      key={item.id}
                      href={`${storePath}/${item.permalink}`}
                      image={item.image}
                      title={item.title}
                      isProduct={false}
                    />
                  ))}
                </div>
              </>
            )}
          </div>
        </main>
      </PageLayout>
      <Footer />
    </>
  );
};

export default StoreBase;
