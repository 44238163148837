import REDUCER_TYPES from 'constants/reducerTypes';
import REQUEST_SHAPE from 'constants/requestShape';
import { routes } from 'routing/routes';
import Swal from 'sweetalert2';

export const setState = (state, key, payload) => ({ ...state, [key]: payload });

export const setStore = (type, payload) =>
  payload !== undefined ? { type, payload } : { type };

export const processActionCreatorSetters = (settersArray) => {
  const setters = {};

  settersArray.forEach((item) => {
    const setterName = item[0];
    const setterConst = item[1];

    setters[setterName] = (value) => setStore(setterConst, value);
  });

  return setters;
};

export const processActionCreatorStatusFunctions = (statusArray) => {
  const statuses = {};

  statusArray.forEach((item) => {
    const statusFunctionName = item[0];
    const statusConst = item[1];

    statuses[`${statusFunctionName}Loading`] = () =>
      setStore(`${statusConst}_LOADING`);
    statuses[`${statusFunctionName}Success`] = () =>
      setStore(`${statusConst}_SUCCESS`);
    statuses[`${statusFunctionName}Error`] = (message) =>
      setStore(`${statusConst}_ERROR`, message);
  });

  return statuses;
};

export const setStateRequest = (state, key, status, errorMessage = 'ERROR') => {
  const options = {
    [REQUEST_SHAPE.LOADING]: {
      loading: true,
      done: false,
      error: '',
    },
    [REQUEST_SHAPE.ERROR]: {
      loading: false,
      done: true,
      error: errorMessage,
    },
    [REQUEST_SHAPE.SUCCESS]: {
      loading: false,
      done: true,
      error: '',
    },
  };

  const content = options[status];

  return {
    ...state,
    [key]: { ...content },
  };
};

export const reducerMatch = (type, payload, reducers, state) => {
  let result = null;
  const status = ['LOADING', 'SUCCESS', 'ERROR'];

  reducerloop: for (let i = 0; i < reducers.length; i++) {
    const reducerType = reducers[i][0];
    const constant = reducers[i][1];
    const reducerField = reducers[i][2];
    const customValue = reducers[i][3];

    if (reducerType === REDUCER_TYPES.REQUEST_DATA) {
      for (let j = 0; j < status.length; j++) {
        if (type === `${constant}_${status[j]}`) {
          result = setStateRequest(
            state,
            reducerField,
            REQUEST_SHAPE[status[j]],
            payload
          );
          break reducerloop;
        }
      }
    } else if (reducerType === REDUCER_TYPES.SETTER) {
      if (type === constant) {
        result = setState(state, reducerField, customValue || payload);
        break;
      }
    }
  }

  return result;
};

export const objectToFormData = (obj) => {
  const pairs = Object.entries(obj);
  const data = new FormData();
  pairs.forEach((pair) => data.append(pair[0], pair[1]));
  return data;
};

export const getDiscount = (price, priceDiscount) =>
  Math.round(((price - priceDiscount) * 100) / price);

export const formatPrice = (n) => {
  const formatted =
    '$ ' +
    parseInt(n)
      .toFixed(2)
      .replace('.', ',')
      .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1.');

  const split = formatted.split(',');

  return [split[0], split[1]];
};

export const classnameDefaults = (arr) => {
  if (arr.length % 2 !== 0) return '';

  const classes = [];

  for (let i = 0; i < arr.length - 1; i++) {
    const bool = arr[i];
    const className = arr[i + 1];

    if (bool) classes.push(className);
  }

  return classes.join(' ');
};

export const fireDialog = async (
  title,
  message,
  type,
  additionalProps = {}
) => {
  const swalObj = {
    icon: type,
    title,
    confirmButtonColor: '#24253d',
    heightAuto: false,
    ...additionalProps,
  };

  if (message) {
    swalObj.text = message;
  }

  const result = await Swal.fire(swalObj);
  return result;
};

export const fireError = async (title, message) => {
  await fireDialog(title, message, 'error');
};

export const fireSuccess = async (title, message) => {
  await fireDialog(title, message, 'success');
};

export const fireCartSuccess = async (title, message, history) => {
  const dialogResult = await fireDialog(title, message, 'success', {
    showConfirmButton: true,
    showCancelButton: true,
    confirmButtonText: 'Ir al checkout',
    cancelButtonText: 'Seguir comprando',
  });

  if (dialogResult.isConfirmed) history.push(routes.checkout.path);
};

export const firePurchaseSuccess = async (title, message, paymentLink) => {
  await fireDialog(title, message, 'success', {
    timer: 2000,
    allowOutsideClick: false,
    allowEscapeKey: false,
    didOpen: function () {
      Swal.showLoading();
    },
  });

  window.location.href = paymentLink;
};

export const checkBooleanFields = (requestDataArr) => {
  const orFields = ['loading', 'error'];
  const andFields = ['done'];
  const data = {
    [orFields[0]]: false,
    [orFields[1]]: false,
    [andFields[0]]: false,
  };

  const andFieldsDone = [0];

  requestDataArr.forEach((item) => {
    orFields.forEach((field) => {
      if (item[field]) {
        data[field] = true;
      }
    });

    andFields.forEach((field, index) => {
      if (item[field]) {
        andFieldsDone[index]++;
      }
    });
  });

  andFields.forEach((field, index) => {
    if (andFieldsDone[index] === requestDataArr.length) {
      data[field] = true;
    }
  });

  return data;
};
