import Header from './Header';
import { connect } from 'react-redux';
import { getUser } from 'store/redux/auth/selectors';
import { getCartItemsAmountLabels } from 'store/redux/cart/selectors';
import { logout } from 'store/redux/auth/actionCreators';

const mapStateToProps = (state) => ({
  user: getUser(state),
  cartItemsAmountLabels: getCartItemsAmountLabels(state),
});

const mapDispatchToProps = {
  logout,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
