import { useState } from 'react';
import { useEffect } from 'react';
import api from 'store/api';

const useHome = (fetchObject, errorMessage, dependencies = []) => {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchCall = async () => {
      setLoading(true);
      try {
        const response = await api(fetchObject);

        if (!response.data.status) {
          setError(response.data.message || errorMessage);
          return;
        }

        setData(response.data.data);
      } catch (err) {
        setError(errorMessage);
      } finally {
        setLoading(false);
        setDone(true);
      }
    };

    fetchCall();
  }, [errorMessage, ...dependencies]);

  return [error, loading, done, data];
};

export default useHome;
