export const SET_AUTH_TOKEN = 'SET_AUTH_TOKEN';
export const REMOVE_AUTH_TOKEN = 'REMOVE_AUTH_TOKEN';
export const SET_USER = 'SET_USER';
export const SET_AUTH_CHECK_DONE = 'SET_AUTH_CHECK_DONE';
export const CLEAR_STATE = 'CLEAR_STATE';
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const REGISTER = 'REGISTER';
export const CLEAR_REGISTER = 'CLEAR_REGISTER';
export const RECOVER_PASSWORD = 'RECOVER_PASSWORD';
export const CLEAR_RECOVER_PASSWORD = 'CLEAR_RECOVER_PASSWORD';
export const GET_NEW_PASSWORD = 'GET_NEW_PASSWORD';
