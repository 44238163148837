import LogoImage from "assets/images/logo.png";
import { useState } from "react";
import { classnameDefaults } from "utils/common";
import { Link } from "react-router-dom";
import { routes } from "routing/routes";
import Cambios from "components/Footer/DialogContent/Cambios";
import ComoPagar from "components/Footer/DialogContent/ComoPagar";
import DondeEstamos from "components/Footer/DialogContent/DondeEstamos";
import MetodosEnvio from "components/Footer/DialogContent/MetodosEnvio";
import PoliticasDevolucion from "components/Footer/DialogContent/PoliticasDevolucion";
import ComprasComunitarias from "./DialogContent/ComprasComunitarias";
import LogoDev from "assets/images/logo-dev.png";
import QR from "assets/images/qr-big.png";
import Provedores from "./DialogContent/Proveedores";
import CapacidadesDiferentes from "./DialogContent/CapacidadesDiferentes";
import PuntoRetiro from "./DialogContent/PuntoRetiro";
import { NavLink } from "react-router-dom";

const Footer = () => {
  const [whatsappPopupOpen, setWhatsappPopupOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [qrOpen, setQrOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState(null);

  const handleDialogOpen = (component) => {
    setDialogContent(component);
    setDialogOpen(true);
  };

  return (
    <>
      <div className={`qr_overlay ${classnameDefaults([qrOpen, "active"])}`}>
        <button onClick={() => setQrOpen(false)}>
          <i className="fas fa-times"></i>
        </button>
        <img src={QR} alt="Escanea y pagá" />
      </div>
      <div
        className={`pop-overlay ${classnameDefaults([dialogOpen, "active"])}`}
      >
        <i className="fas fa-times" onClick={() => setDialogOpen(false)}></i>
        <div className="pop-box">{dialogContent}</div>
      </div>
      <footer>
        <div className="wrapper">
          <div className="cols">
            <div className="col col-1">
              <a href=".">
                <img src={LogoImage} alt="Logotipo Any Natural" />
              </a>
            </div>
            {/*  <div className="col col-1-5">
              <a onClick={() => setQrOpen(true)}>
                <img src={QR} alt="Escanea y pagá" />
              </a>
            </div> */}
            <div className="col col-2">
              <div>
                <a onClick={() => handleDialogOpen(PoliticasDevolucion)}>
                  Devolución / cambios
                </a>
                <a onClick={() => handleDialogOpen(MetodosEnvio)}>
                  Métodos de envío
                </a>
                {/*  <a onClick={() => handleDialogOpen(Cambios)}>Cambios</a> */}
              </div>
            </div>
            <div className="col col-2">
              <div>
                <a onClick={() => handleDialogOpen(ComoPagar)}>Cómo Pagar</a>
                <a onClick={() => handleDialogOpen(ComprasComunitarias)}>
                  Compras comunitarias
                </a>
                {/*  <a onClick={() => handleDialogOpen(MetodosEnvio)}>
                  Cooperadoras
                </a> */}

                {/*  <a onClick={() => handleDialogOpen(Cambios)}>Amigxs Vegan</a> */}
              </div>
            </div>
            <div className="col col-2">
              <div>
                {/*  <a onClick={() => handleDialogOpen(PoliticasDevolucion)}>
                  Recomendemos
                </a> */}
                {/*  <a onClick={() => handleDialogOpen(MetodosEnvio)}>
                  Espacios Holísticos
                </a> */}
                <a href="https://anynatural.com.ar/blog" target="_blank">
                  El blog de Any!
                </a>
                <a onClick={() => handleDialogOpen(PuntoRetiro)}>
                  Dónde estamos
                </a>
                {/* <a onClick={() => handleDialogOpen(ComoPagar)}>Vida Sana</a> */}
              </div>
            </div>
            <div className="col col-2">
              <div>
                {/* <a onClick={() => handleDialogOpen(CapacidadesDiferentes)}>
                  Capacidades diferentes
                </a> */}
                <a
                  href="https://instagram.com/any.natural?igshid=MzRlODBiNWFlZA=="
                  target="_blank" /* onClick={() => handleDialogOpen(DondeEstamos)} */
                >
                  En redes
                </a>
                <NavLink to="tienda/alacena/ts-yerbas-infusiones/yerba-mate-la-buena-x-1kg">
                  Ofertas
                </NavLink>

                {/*  <a onClick={() => handleDialogOpen(Provedores)}>Proovedores</a> */}
              </div>

              {/*<div>
                <a onClick={() => handleDialogOpen(PuntoRetiro)}>
                  Punto de retiro
                </a>
                <a onClick={() => handleDialogOpen(PoliticasDevolucion)}>
                  Envios a domicilio
                </a>
                <p>
                  <i className="fas fa-envelope"></i>info@anynatural.com.ar
                </p>
                <p>
                  <i className="fas fa-phone"></i>
                  <a
                    href="tel:+5491150193968"
                    style={{ color: "inherit", textDecoration: "none" }}
                  >
                    +54 9 11 5019 3968
                  </a>
                </p>
                <p>
                  <i className="fas fa-map-marker-alt"></i>Showroom: <br />
                  <span>Virrey del pino 2720, Belgrano</span>
                </p> 
          </div>*/}
            </div>
            {/*    <div className="col col-3">
              <div className="redes">
                <a target="_blank" href="https://www.facebook.com/Any-Natural">
                  <i className="fab fa-facebook-square"></i>
                </a>
                <a
                  target="_blank"
                  href="https://www.instagram.com/Any-Natural/"
                >
                  <i className="fab fa-instagram"></i>
                </a>
              </div>
            </div> */}
          </div>
        </div>
      </footer>
      <div className="under-footer">
        <div className="wrapper">
          <p>Todos los derechos reservados @ {new Date().getFullYear()}</p>
          <a href="https://athom.com.ar/" target="_blank">
            <img src={LogoDev} alt="Logo de Athom" />
          </a>
        </div>
      </div>
      <button
        className="whatsapp-opener"
        onClick={() => setWhatsappPopupOpen(true)}
      >
        <i className="fab fa-whatsapp"></i>
        <span>Chateá con nosotros!</span>
      </button>

      <div
        className={`whatsapp-container ${classnameDefaults([
          whatsappPopupOpen,
          "active",
        ])}`}
      >
        <div className="top">
          <i
            className="fas fa-times"
            onClick={() => setWhatsappPopupOpen(false)}
          ></i>
          <p>
            ¡Hola! Hacé click en nuestra marca y te responderemos lo antes
            posible
          </p>
        </div>
        <a
          className="bottom"
          href="https://api.whatsapp.com/send?phone=5491127863054"
          target="_blank"
          rel="noreferrer"
        >
          <i className="fab fa-whatsapp"></i>
          <div>
            <span>Estamos para ayudarte</span>
            <p>anynatural.com.ar</p>
          </div>
        </a>
      </div>
    </>
  );
};

export default Footer;
