import { Link } from "react-router-dom";
import { formatPrice, getDiscount } from "utils/common";

const StoreCard = ({
  image,
  title,
  href,
  isProduct,
  product,
  imagePath,
  index,
}) => {
  const priceParts = product && formatPrice(product.price);
  const priceDiscountParts = product && formatPrice(product.price_discount);

  const link =
    href ||
    `tienda/${product.category.permalink}/${product.category.subcategory.permalink}/${product.permalink}`;

  return (
    <Link to={link} className="product" data-index={index}>
      <div className="top-part">
        <img src={`${imagePath}${image}`} alt={title} />
      </div>
      <div className="bottom-part">
        <p className="title">{title}</p>
        {isProduct && (
          <>
            {product === "1" ? (
              <>
                <div className="price">
                  <div className="price-after">
                    <span>{priceDiscountParts[0]}</span>
                    <span className="product-decimal">
                      {priceDiscountParts[1]}
                    </span>
                  </div>
                  <div className="price-before">
                    <span>{priceParts[0]}</span>
                    <span className="product-decimal">{priceParts[1]}</span>
                  </div>
                </div>
                {/* <p className='discount'>
                  <span>
                    {getDiscount(product.price, product.price_discount)} % de
                    descuento
                  </span>
                </p> */}
              </>
            ) : (
              <>
                <div className="price no-discount">
                  <div className="price-after">
                    <span>{priceParts[0]}</span>
                    <span className="product-decimal">{priceParts[1]}</span>
                  </div>
                </div>
                <p className="discount hide-discount">
                  <span>.</span>
                </p>
              </>
            )}
          </>
        )}
      </div>
    </Link>
  );
};

export default StoreCard;
