import PurchaseStatus from './PurchaseStatus';
import { connect } from 'react-redux';
import {
  getPurchaseStatus,
  getPurchaseStatusRequestData,
} from 'store/redux/cart/selectors';
import {
  fetchPurchaseStatus,
  resetPurchaseSearch,
} from 'store/redux/cart/actionCreators';

const mapStateToProps = (state) => ({
  purchaseStatus: getPurchaseStatus(state),
  purchaseStatusRequestData: getPurchaseStatusRequestData(state),
});

const mapDispatchToProps = {
  fetchPurchaseStatus,
  resetPurchaseSearch,
};

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseStatus);
