import PageLayout from "components/common/PageLayout";
import Header from "components/Header";
import Footer from "components/Footer";
import { useParams } from "react-router-dom";
import useFetch from "hooks/useFetch";
import ENDPOINTS from "constants/endpoints";
import { routes } from "routing/routes";
import ErrorMessage from "components/common/ErrorMessage";
import Breadcrumb from "components/Store/Breadcrumb/Breadcrumb";
import React from "react";
import Product from "components/Store/StoreProduct/Product";
import { useTitle } from "react-use";
import ScrollToTop from "components/ScrollToTop";

const StoreCategory = ({
  authToken,
  user,
  addToCart,
  addToCartRequestData,
}) => {
  const params = useParams();
  const [error, loading, done, data] = useFetch(
    {
      method: "get",
      url: ENDPOINTS.getProduct(),
      params: {
        category: params.category,
        subcategory: params.subcategory,
        product: params.product,
        token: authToken,
      },
    },
    "Error al cargar las categorías, actualizar la pagina para reintentar.",
    [params.category, params.subcategory, params.product, authToken]
  );

  const breadcrumb = (data) => {
    const { category, subcategory, product } = data;
    return [
      {
        link: storePath,
        label: "Tienda",
      },
      {
        link: `${storePath}/${category.permalink}`,
        label: category.title,
      },
      {
        link: `${storePath}/${category.permalink}/${subcategory.permalink}`,
        label: subcategory.title,
      },
      {
        link: `${storePath}/${category.permalink}/${subcategory.permalink}/${product.permalink}`,
        label: product.title,
      },
    ];
  };

  const storePath = routes.tienda.base.path;

  const productProps = {
    product: data?.product,
    addToCart,
    addToCartRequestData,
    user,
  };

  useTitle(`${data?.product?.title} | ${process.env.REACT_APP_PAGE_TITLE}`);

  return (
    <>
      <PageLayout>
        <ScrollToTop />
        <Header />

        <main>
          <div className="wrapper">
            {loading && (
              <div className="store-loading">
                <i className="fas fa-spinner fa-pulse"></i>
                <span>Cargando</span>
              </div>
            )}
            {!!error && (
              <ErrorMessage
                message={error}
                linkLabel="Volver a la tienda"
                link={storePath}
              />
            )}
            {done && !error && (
              <>
                <Breadcrumb data={breadcrumb(data)} />
                <Product {...productProps} />
              </>
            )}
          </div>
        </main>
      </PageLayout>
      <Footer />
    </>
  );
};

export default StoreCategory;
