import Checkout from './Checkout';
import { connect } from 'react-redux';
import { fetchCheckout, cancelPurchase } from 'store/redux/cart/actionCreators';
import {
  getCheckout,
  getCheckoutRequestData,
  getCancelPurchaseRequestData,
} from 'store/redux/cart/selectors';

const mapStateToProps = (state) => ({
  checkout: getCheckout(state),
  checkoutRequestData: getCheckoutRequestData(state),
  cancelPurchaseRequestData: getCancelPurchaseRequestData(state),
});

const mapDispatchToProps = {
  fetchCheckout,
  cancelPurchase,
};

export default connect(mapStateToProps, mapDispatchToProps)(Checkout);
