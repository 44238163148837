export const SET_CART = 'SET_CART';
export const ADD_TO_CART = 'ADD_TO_CART';
export const FETCH_CHECKOUT = 'FETCH_CHECKOUT';
export const SET_CHECKOUT = 'SET_CHECKOUT';
export const CHANGE_AMOUNT_CART = 'CHANGE_AMOUNT_CART';
export const SET_CHANGE_AMOUNT_CART_ID = 'SET_CHANGE_AMOUNT_CART_ID';
export const DELETE_PRODUCT_CART = 'DELETE_PRODUCT_CART';
export const SET_DELETE_PRODUCT_CART_ID = 'SET_DELETE_PRODUCT_CART_ID';
export const SUBMIT_PURCHASE = 'SUBMIT_PURCHASE';
export const CANCEL_PURCHASE = 'CANCEL_PURCHASE';
export const FETCH_PURCHASE_STATUS = 'FETCH_PURCHASE_STATUS';
export const SET_PURCHASE_STATUS = 'SET_PURCHASE_STATUS';
export const SEND_MESSAGE = 'SEND_MESSAGE';
