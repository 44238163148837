import './App.scss';
import './overrides.scss';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from 'store/redux';
import AppContainer from 'components/AppContainer';

const App = () => {
  return (
    <Provider store={store}>
      <Router>
        <AppContainer />
      </Router>
    </Provider>
  );
};

export default App;
